// import Swiper styles
import 'swiper/css';
// import Swiper JS
import Swiper from 'swiper/bundle';
var maxslides = document.getElementById("maxSliedes").innerHTML;
var randomnumber = Math.floor(Math.random() * (maxslides - 0 + 1)) + 0;

var swiper = new Swiper('.js-swiper--standard', {
  initialSlide: randomnumber,
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 10,
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  // Pagination fraction numbers
 
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});
var swiper = new Swiper(".mySwiper", {
  loop: false,
  spaceBetween: 10,
  slidesPerView: 3,
  freeMode: true,
  watchSlidesProgress: true,
});
var swiper2 = new Swiper(".mySwiper2", {
  loop: false,
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    clickable: true,
  },
  thumbs: {
    swiper: swiper,
  },
});
export default Swiper;
